






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Header from '@/types/tables';
import { getStatisticSessions, getDailyStatisticSessions } from '@/api';
import { Customer } from '@/types/customers';
import FusionCharts from 'fusioncharts';
import startOfToday from 'date-fns/startOfToday';
import endOfToday from 'date-fns/endOfToday';
import startOfYesterday from 'date-fns/startOfYesterday';
import endOfYesterday from 'date-fns/endOfYesterday';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import subMonths from 'date-fns/subMonths';

@Component({name: 'StatisticSessions'})
export default class StatisticSessions extends Vue {
    // TODO type!
    sessions = [];
    loading = false;
    toggle_date_range = 0;
    lang = {
        loading: 'Загрузка...',
        months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
        shortMonths: ['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек'],
        exportButtonTitle: 'Экспорт',
        printButtonTitle: 'Печать',
        rangeSelectorFrom: 'С',
        rangeSelectorTo: 'По',
        rangeSelectorZoom: 'Период',
        downloadPNG: 'Скачать PNG',
        downloadJPEG: 'Скачать JPEG',
        downloadPDF: 'Скачать PDF',
        downloadSVG: 'Скачать SVG',
        printChart: 'Напечатать график',
    };

    async created() {
        await this.dateRangeChange();
    }

    @Watch('date_range_changed')
    async dateRangeChange() {
        let start: Date = new Date();
        let end: Date = new Date();
        const today = new Date();
        await this.$nextTick();
        this.sessions = [];
        switch (this.toggle_date_range) {
            case 0:
                start = startOfToday();
                end = endOfToday();
                break;
            case 1:
                start = startOfYesterday();
                end = endOfYesterday();
                break;
            case 2:
                start = startOfWeek(today);
                end = endOfWeek(today);
                break;
            case 3:
                start = subDays(today, 7);
                end = today;
                break;
            case 4:
                start = startOfMonth(today);
                end = endOfMonth(today);
                break;
            case 5:
                start = subDays(today, 30);
                end = today;
                break;
            case 6:
                start = startOfMonth(subMonths(today, 1));
                end = endOfMonth(subMonths(today, 1));
                break;
        }
        this.loading = true;
        try {
            const sessions = await getStatisticSessions(start, end);
            this.sessions = sessions.sessions;
        } catch (e) {
        } finally {
            this.loading = false;
        }
    }

}
